<template>
  <div>
    <div class="title-bar">
      <h1>Проблемный Тикет</h1>
      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'problemTicketList'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>
        <el-button type="warning" @click="handleCloseTicket">
          <i class="el-icon-close" />
          Закрыть
        </el-button>
        <el-button type="success" @click="handleOpenTicket">
          Открыть
        </el-button>
        <el-button type="danger" @click="handleDeleteTicket">
          <i class="el-icon-delete" />
          Удалить
        </el-button>
      </div>
    </div>

    <MainPanel v-loading="loading" class="panel--mb">
      <table v-if="ticket" class="key-values-table">
        <tr>
          <td><strong>ID</strong></td>
          <td>{{ ticket && ticket.id }}</td>
        </tr>
        <tr>
          <td><strong>Категория</strong></td>
          <td>{{ ticket && ticket.ticket.category }}</td>
        </tr>
        <tr>
          <td><strong>Причина</strong></td>
          <td>{{ ticket && ticket.reason }}</td>
        </tr>
        <tr>
          <td><strong>Статус</strong></td>
          <td>{{ ticket && ticket.status }}</td>
        </tr>
        <tr>
          <td><strong>Пользователь</strong></td>
          <td>{{ ticket && ticket.ticket.user }}</td>
        </tr>
      </table>
      <router-link class="ticket__button" :to="{name: 'ViewTicketPage', params: {id : ticket.ticket.id}}">
        <el-button type="info">
          Первоначальный тикет
        </el-button>
      </router-link>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import { mapActions } from 'vuex';

export default {
  metaInfo: {
    title: 'Тикет',
  },
  components: { 
    MainPanel,
  },
  data() {
    return {
      ticket: null,
      loading: true,
    };
  },
  async created() {
    await this.getTicket();
    this.loading = false;
  },
  methods: {
    ...mapActions('help-center/problemTicket', [
      'getProblemTicketById',
      'closeProblemTicket',
      'openProblemTicket',
      'deleteProblemTicket',
    ]),
    async handleCloseTicket() {
      const res = await this.closeProblemTicket(this.$route.params.id);
      if (!res) return;
      await this.getTicket()
      this.$message({
        message: 'Тикет успешно закрыт.',
        type: 'success',
      });
    },
    async handleOpenTicket() {
      const res = await this.openProblemTicket(this.$route.params.id);
      if (!res) return;
      await this.getTicket();
      this.$message({
        message: 'Тикет успешно открыт.',
        type: 'success',
      });
    },
    async handleDeleteTicket() {
      const res = await this.deleteProblemTicket(this.$route.params.id);
      if (!res) return;
      this.$message({
        message: 'Тикет успешно удален.',
        type: 'success',
      });
      this.$router.push({name: 'problemTicketList'})
    },
    async getTicket() {
      this.ticket = await this.getProblemTicketById(this.$route.params.id);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.panel--mb
  margin-bottom: 25px

.key-values-table
  width: 100%
.ticket__button
  width: 100%
  margin-top: 30px
  display: block  
  button
    width: 100%
</style>
