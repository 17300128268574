<template>
  <div v-if="internalWish">
    <PageHeader link-name="Назад" link-path="InternalWishes" page-name="Внутрений Тендер" />
    <el-card class="box-card">
      <el-descriptions border :column="1">
        <el-descriptions-item label="Пользователь">{{ internalWish.user.show_name }}</el-descriptions-item>
        <el-descriptions-item label="Категория">{{ internalWish.category.name }}</el-descriptions-item>
        <el-descriptions-item label="Населенный пункт">{{ internalWish.city.name }}</el-descriptions-item>
        <el-descriptions-item label="Область">{{ internalWish.city.region_name }}</el-descriptions-item>
        <el-descriptions-item label="Состояние">{{ internalWish.condition }}</el-descriptions-item>
        <el-descriptions-item label="Дата создание">{{ internalWish.created_at }}</el-descriptions-item>
        <el-descriptions-item label="Статус">{{ internalWish.state }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'ViewPage',
  components:{PageHeader},
  data(){
    return {
      internalWish: null,
    }
  },

  async created() {
    this.internalWish = await this.fetchInternalWish(this.$route.params.id);
  },

  methods: {
    ...mapActions('internalWishes', [
      'fetchInternalWish',
    ]),
  },
}

</script>

