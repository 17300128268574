import api from '@/utils/api'

export default {
  state: () => ({
    categories: [],
    categoriesPromise: null,
    isInitialized: false,
  }),
  mutations: {
    setCategories (state, categories) {
      state.categories = categories
    },
    setCategoriesPromise (state, categoriesPromise) {
      state.categoriesPromise = categoriesPromise
    },
    setIsInitialized(state) {
      state.isInitialized = true
    },
  },
  getters: {
    getCategoriesForSelector (state) {
      const mapper = (categories) => {
        return categories.map(item => {
          let result = {
            value: item.id,
            label: item.name,
          }

          if (item.has_children) {
            result.children = mapper(item.children)
          }

          return result
        })
      }

      return mapper(state.categories)
    },
  },
  actions: {
    fetchCategories({state, commit}) {
      if (state.isInitialized) {
        return new Promise((resolve) => {
          resolve(state.categories)
        })
      }

      if (state.categoriesPromise) {
        return state.categoriesPromise
      }

      const categoriesPromise = api.http.get('admin/categories')

      categoriesPromise.then(response => {
        commit('setCategories', response.data.data)
        commit('setCategoriesPromise', null)
        commit('setIsInitialized')

        return response.data.data
      }).catch(() => {
        commit('setCategoriesPromise', null)
      })

      return categoriesPromise;
    },
  },
  namespaced: true,
}
