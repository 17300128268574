import api from '@/utils/api'

export default {
  actions: {
    async fetchInternalUsers() {
      const res = await api.http.get('/admin/users/internal')
      return res?.data.data
    },

    async fetchInternalUser(_, id) {
      const res = await api.http.get(`/admin/users/internal/${id}`)
      return res?.data.data
    },

    async fetchPackagesType(_, type) {
      const res = await api.http.get(`/admin/users/internal/packages?account_type=${type}`)
      return res?.data?.data
    },

    async createUser(_, form) {
      await api.http.post('/admin/users/internal', form)
    },

    async uploadImage(_, file) {
      const res =  await api.http.post('/admin/users/internal/upload-avatar', file)
      return res.data.data.id
    },

    async changeUser(_, form) {
      await api.http.patch(`/admin/users/internal/${form.id}`, form)
    },
  },


  namespaced: true,
}
