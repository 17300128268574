<template>
  <div>
    <div style="text-align: center">
      <span style="margin-right:20px">Диапазон дат</span>
      <el-date-picker
        v-model="dataRange"
        format="yyyy/MM/dd"
        type="daterange"
        value-format="yyyy-MM-dd"
        @change="newDataRange"
      />
    </div>
    <el-table
      :data="statistics"
      :row-class-name="tableRowClassName"
      style="width: 100%; margin-top: 50px"
    >
      <el-table-column
        align="center"
        label="Имя"
        prop="name"
      />
      <el-table-column
        align="center"
        label="На данный момент"
        prop="currentCount"
      />
      <el-table-column
        align="center"
        label="Было"
        prop="prevCount"
      />
      <el-table-column
        align="center"
        label="Прирост"
        prop="diff"
      />
    </el-table>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {format} from 'date-fns';

export default {
  name: 'List',
  data() {
    return {
      statistics: [],
      date: new Date(),
      dataRange:[],
    }
  },

  async mounted() {
     await this.getStatistics()
  },

  methods:{
    ...mapActions('statistics', [
      'fetchStatistics',
    ]),

    newDataRange(){
       this.getStatistics()
    },
    async getStatistics() {
      this.statistics = []

      const date = new Date()
      const defaultDate = format(date, 'yyyy-MM-dd')

       if(!this.dataRange.length){
        this.dataRange.push(defaultDate)
        this.dataRange.push(defaultDate)
       }

      const res = await this.fetchStatistics({dateFrom :this.dataRange[0], dateTo:this.dataRange[1]})

      const users = {
        name: 'Пользователи',
        currentCount: res.data.users.count.current,
        prevCount: res.data.users.count.prev,
        diff: res?.data.users.count.current - res?.data.users.count.prev,
      }
      const usersRegistration = {
        name: 'Новые регистрации',
        currentCount: res.data.users.registrations.current,
        prevCount: res.data.users.registrations.prev,
        diff: res?.data.users.registrations.current - res?.data.users.registrations.prev,
      }

      const wishesClosed = {
        name:'Закрытых тендеров',
        currentCount: res?.data.wishes.closed.current,
        prevCount: res?.data.wishes.closed.prev,
        diff: res?.data.wishes.closed.current - res?.data.wishes.closed.prev,
      }

      const wishesPublishedNonOfficial = {
        name:'Тендеры опубликованные (реальные)',
        currentCount: res?.data.wishes.published_non_official.current ,
        prevCount: res?.data.wishes.published_non_official.prev,
        diff: res?.data.wishes.published_non_official.current - res?.data.wishes.published_non_official.prev ,
      }

      const wishesPublished = {
        name:'Тендеры опубликованные ',
        currentCount: res?.data.wishes.published.current ,
        prevCount: res?.data.wishes.published.prev,
        diff: res?.data.wishes.published.current - res?.data.wishes.published.prev,
      }

      const offersPublished = {
        name:'Предложения опубликованные ',
        currentCount: res?.data.offers.published.current ,
        prevCount: res?.data.offers.published.prev,
        diff: res?.data.offers.published.current - res?.data.offers.published.prev,
      }

      const offersPublishedNonOfficial = {
        name:'Предложения опубликованные (реальные)',
        currentCount: res?.data.offers.published_non_official.current ,
        prevCount: res?.data.offers.published_non_official.prev,
        diff: res?.data.offers.published_non_official.current - res?.data.offers.published_non_official.prev,
      }

      this.statistics.push(users)
      this.statistics.push(usersRegistration )
      this.statistics.push(wishesClosed)
      this.statistics.push(wishesPublished)
      this.statistics.push(wishesPublishedNonOfficial)
      this.statistics.push(offersPublishedNonOfficial)
      this.statistics.push(offersPublished)

    },

    tableRowClassName({row}) {
      if (row.diff > 0) {
        return 'success-row';
      } else if (row.diff < 0) {
        return 'warning-row';
      }
      return '';
    },
  },
}
</script>

<style>

.el-table .warning-row td {
  background: #debd84;
}

.el-table .success-row td {
  background: #9fd570;
}
</style>
