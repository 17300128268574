<template>
  <div>
    <PageHeader page-name="Внутренние тендеры">
      <template>
        <el-select
          v-model="selectedStatus"
          placeholder="Статус"
        >
          <el-option
            v-for="item in status"
            :key="item.state"
            :label="item.state"
            :value="item.state"
          />
        </el-select>
        <el-select
          v-model="selectedName"
          placeholder="Имя"
        >
          <el-option
            v-for="item in names"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          />
        </el-select>
        <el-button
          icon="el-icon-search"
          type="primary"
          @click="filter"
        >
          Поиск
        </el-button>
        <el-button
          v-if="selectedName || selectedStatus"
          icon="el-icon-delete"
          type="primary"
          @click="clear"
        >
          Сброс
        </el-button>
      </template>
    </PageHeader>
    <MainPanel>
      <el-table
        :data="internalWishes"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="Название"
          prop="name"
        />
        <el-table-column label="Изображение">
          <template slot-scope="scope">
            <el-avatar
              v-if="scope.row.images[0].path"
              class="el-avatar"
              fit="contain"
              shape="square"
              :size="100"
              :src="scope.row.images[0].path"
            />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Категория"
          prop="category.name"
        />
        <el-table-column
          align="center"
          label="Статус"
          prop="state"
        />
        <el-table-column
          align="center"
          label="Пользователь-автор"
          prop="user.short_name"
        />
        <el-table-column
          align="center"
          label="Просмотр"
          prop="public_id"
        >
          <template slot-scope="scope">
            <router-link class="name-wrapper" :to="{name: 'InternalWish', params: {id: scope.row.id}}">
              <el-button
                circle
                icon="el-icon-user-solid"
                type="warning"
              />
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </MainPanel>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import PageHeader from '@/components/common/PageHeader';
import MainPanel from '@/components/common/MainPanel';

export default {
  name: 'ListPage',
  components: {PageHeader, MainPanel},

  data() {
    return {
      internalWishes: null,
      status: null,
      selectedStatus: null,
      names: null,
      selectedName:null,

    }
  },

  async created() {
    await this.getInternalWishes()
    await this.findStates()
  },

  methods: {
    ...mapActions('internalWishes', [
      'fetchInternalWishes',
    ]),
    ...mapActions('internalUsers', [
      'fetchInternalUsers',
    ]),

    async getInternalWishes() {
      this.internalWishes = await this.fetchInternalWishes({user_id: this.selectedName, state: this.selectedStatus})
      this.names = await this.fetchInternalUsers()
    },

    findStates() {
      let states = []
      this.internalWishes.forEach((el) => states.push(el.state))
      this.status = this.arrTransform(states)
    },

    async filter() {
      this.internalWishes = await this.fetchInternalWishes({user_id: this.selectedName, state: this.selectedStatus})
    },

    clear() {
        this.selectedStatus = null;
        this.selectedName = null;
        this.getInternalWishes()
    },

    arrTransform(arr) {
      const uniqElements = new Set([...arr])
      const uniqArr = Array.from(uniqElements)
      return uniqArr.map((state) => ({state}))
    },
  },
}
</script>
