<template>
  <el-table :data="list && list.data" stripe style="width: 100%">
    <el-table-column label="Создан" prop="created" width="140" />

    <el-table-column label="Тип" width="130">
      <template slot-scope="scope">
        {{ scope.row.type === 'wish' ? 'Желание' : '' }}
        {{ scope.row.type === 'offer' ? 'Предложение' : '' }}
      </template>
    </el-table-column>

    <el-table-column label="Причина" prop="reason" />

    <el-table-column label="Объект жалобы" width="350">
      <template slot-scope="scope">
        <router-link class="name-wrapper" :to="{name: scope.row.object_type === 'wish' ? 'ViewWish' : 'ViewOffer', params: {id: scope.row.object.id}}">
          <img
            v-if="scope.row.object.first_image"
            alt="Avatar"
            class="avatar"
            :src="scope.row.object.first_image.path"
          >
          {{ scope.row.object.name }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column label="Кто жаловался" width="250">
      <template slot-scope="scope">
        <UserCard :user="scope.row.user" />
      </template>
    </el-table-column>

    <el-table-column label="На кого жаловались" width="250">
      <template slot-scope="scope">
        <UserCard :user="scope.row.object.user" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Статус"
      width="120"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="getStatusTagType(scope.row.status)"
        >
          {{ scope.row.status && scope.row.status.replace('_', ' ') }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import UserCard from '@/components/user-card/UserCard';



export default {
  name: 'List',
  components:{UserCard},
  computed: {
    ...mapState('complaints', {
      list: 'complaints',
    }),
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
  },
  methods: {
    onView(id) {
      this.$router.push({name: 'ViewComplaint', params: {id}})
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase


.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
