<template>
  <div>
    <PageHeader link-name="Назад" link-path="OffersList" page-name="Модерирование Предложения">
      <template>
        <div class="title-bar__button-wrapper">
          <router-link class="button-wrapper-link" :to="{name: 'OffersList'}">
            <el-button type="gray">
              <i class="fas fa-list" /> Список
            </el-button>
          </router-link>

          <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: offerId, subject: 'offer'}}">
            <el-button type="info">
              <i class="fas fa-info-circle" /> Действия над Предложением
            </el-button>
          </router-link>

          <el-button type="success" @click="onSaveOffer">
            Сохранить
          </el-button>
        </div>
      </template>
    </PageHeader>
    <MainPanel v-loading="loading" class="panel--mb">
      <table v-if="offer && wish" class="comparing-table">
        <tr>
          <th>
            <div class="comparing-columns-heading">
              Текущая версия
            </div>
          </th>
          <th v-if="offer.prev_version">
            <div class="comparing-columns-heading">
              Предыдущая версия
            </div>
          </th>
          <th>
            <div class="comparing-columns-heading">
              Желание
            </div>
          </th>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Название
            </div>
            <EditableText v-model="form.name" />
          </td>
          <td v-if="offer.prev_version">
            <div class="comparing-table__label">
              Название
            </div>
            {{ offer.prev_version.data.name }}
          </td>
          <td>
            <div class="comparing-table__label">
              Название
            </div>
            {{ wish.name }}
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Описание
            </div>
            <EditableText v-model="form.description" rows="10" type="textarea" />
          </td>
          <td v-if="offer.prev_version">
            <div class="comparing-table__label">
              Описание
            </div>
            {{ offer.prev_version.data.description }}
          </td>
          <td>
            <div class="comparing-table__label">
              Описание
            </div>
            {{ wish.description }}
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Характеристики
            </div>
            <EditableText v-model="form.characteristic" rows="10" type="textarea" />
          </td>
          <td v-if="offer.prev_version">
            <div class="comparing-table__label">
              Характеристики
            </div>
            {{ offer.prev_version.data.characteristic }}
          </td>
          <td />
        </tr>

        <tr>
          <td v-if="offer.prev_version" />
          <td />
          <td>
            <div class="comparing-table__label">
              Категории
            </div>

            <CategorySelector
              v-model="form.category"
              :disabled="true"
              :placeholder="form.category"
            />
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="form.images"
              removing-allowed
              @change-images="changeImages"
            />
          </td>
          <td v-if="offer.prev_version">
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="offer.prev_version.data.images"
              @change-images="changeImages"
            />
          </td>
          <td>
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="wish.images"
              @change-images="changeImages"
            />
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ offer.video_link || '---' }}
          </td>
          <td v-if="offer.prev_version">
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ offer.prev_version.data.video_link || '---' }}
          </td>
          <td>
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ wish.video_link || '---' }}
          </td>
        </tr>
      </table>
    </MainPanel>


    <MainPanel v-loading="loading">
      <table v-if="offer" class="key-values-table">
        <tr>
          <td>id</td>
          <td>{{ offer.id }}</td>
        </tr>

        <tr v-if="offer.created_at">
          <td>Дата создания</td>
          <td>{{ offer.created_at }}</td>
        </tr>

        <tr v-if="offer.price">
          <td>Цена</td>
          <td>{{ offer.price }} {{ getCurrencyByKey(offer.currency) }}</td>
        </tr>

        <tr v-if="offer.condition">
          <td>Состояние</td>
          <td>{{ getOfferConditionByKey(offer.condition) }}</td>
        </tr>

        <tr v-if="offer.city">
          <td>Населённый пункт</td>
          <td>{{ offer.city.name }}</td>
        </tr>

        <tr v-if="offer.city">
          <td>Область</td>
          <td>{{ offer.city.region_name }}</td>
        </tr>

        <tr v-if="offer.complaints_count">
          <td>Количество жалоб</td>
          <td>{{ offer.complaints_count }}</td>
        </tr>

        <tr v-if="wish">
          <td>Желание</td>
          <td>
            <router-link class="name-wrapper" :to="{name: 'ViewWish', params: {id: wish.id}}">
              <div class="preview-image" :style="`background-image:url(${wish.first_image})`" />
              {{ wish.name }}
            </router-link>
          </td>
        </tr>

        <tr>
          <td>Пользователь</td>
          <td>
            <UserCard :user="offer.user" />
          </td>
        </tr>

        <tr v-if="offer.city">
          <td>Статус</td>
          <td>
            <el-tag
              class="type-tag"
              size="small"
              :type="getStatusTagType(offer.state)"
            >
              {{ offer.state.replace('_', ' ') }}
            </el-tag>
          </td>
        </tr>

        <tr>
          <td>Подтверждение</td>
          <td>
            <el-button-group>
              <el-button
                :disabled="!offer.actions.discard"
                size="small"
                :type="offer.state === 'DISCARDED' ? 'danger' : ''"
                @click="$refs.checkReasonModal.open()"
              >
                Нет
              </el-button>
              <el-button
                :disabled="!offer.actions.approve"
                size="small"
                :type="offer.state === 'CHECKED' ? 'success' : ''"
                @click="onApproveOffer"
              >
                Да
              </el-button>
            </el-button-group>
          </td>
        </tr>

        <tr>
          <td />
          <td><el-button @click="deleteThisOffer">Удалить</el-button></td>
        </tr>
      </table>
    </MainPanel>

    <CheckReasonModal ref="checkReasonModal" @check="onDiscardOffer" />
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions, mapGetters} from 'vuex'
import EditableText from '@/components/common/EditableText'
import ImageViewer from '@/components/common/ImageViewer'
import CheckReasonModal from '@/components/offers/CheckReasonModal'
import CategorySelector from '@/components/wishes/CategorySelector'
import UserCard from '@/components/user-card/UserCard';
import PageHeader from '@/components/common/PageHeader';



export default {
  metaInfo: {
    title: 'Модерирование предложения',
  },
  components: {CategorySelector, CheckReasonModal, ImageViewer, EditableText, MainPanel, UserCard, PageHeader},
  data() {
    return {
      offerId: null,
      offer: null,
      wish: null,
      loading: false,
      timer: null,
      form: {
        name: '',
        description: '',
        characteristic: '',
        categories: [],
        category: null,
      },
    }
  },
  computed: {
    ...mapGetters('value-objects', [
      'getOfferConditionByKey',
      'getStatusTagType',
      'getCurrencyByKey',
    ]),
  },
  async created() {
    this.offerId = this.$route.params.id
    try {
      try {
        await this.takeMutex(this.offerId)
      } catch (e) {
        this.$alert('Это предложение сейчас редактируется другим пользователем. Попробуйте позже');

        throw e;
      }

      await Promise.all([
        this.getOffer(),
        this.fetchOfferConditions(),
        this.fetchCurrencies(),
      ])
      this.wish = await this.fetchWish(this.offer.wish_id)
      this.fillForm()
      this.setMutexLoop()
    } catch (e) {
      this.$router.back()
    }
  },
  async beforeDestroy() {
    clearInterval(this.timer)
    try {
      await this.leaveMutex(this.offerId)
    } catch (e) {

    }
  },
  methods: {
    ...mapActions('offers', [
      'fetchOffer',
      'approveOffer',
      'discardOffer',
      'takeMutex',
      'leaveMutex',
      'updateOffer',
      'deleteOffer',
    ]),
    ...mapActions('wishes', [
      'fetchWish',
    ]),
    ...mapActions('value-objects', [
      'fetchOfferConditions',
      'fetchCurrencies',
    ]),
    async getOffer() {
      this.offer = await this.fetchOffer(this.offerId)
    },
    async onApproveOffer() {
      await this.approveOffer(this.offer.id)
      await this.getOffer()
    },
    async onDiscardOffer(form) {
      await this.discardOffer({
        id: this.offer.id,
        form,
      })
      await this.getOffer()
    },
    async onSaveOffer() {
      await this.updateOffer({
        id: this.offer.id,
        form: this.form,
      })
      await this.$router.push({name: 'OffersList'})
      this.$message.success('Предложение успешно сохранено')
    },
    fillForm() {
      this.form.name = this.offer.name
      this.form.description = this.offer.description
      this.form.characteristic = this.offer.characteristic
      this.form.category = this.wish.category.name
      this.form.images = this.offer.images
    },
    changeImages(res) {
      const clone = {...this.form}
      clone.images = res
      this.form = clone
    },
    setMutexLoop() {
      this.timer = setInterval(() => {
        this.takeMutex(this.offerId)
      }, 60000)
    },
    async deleteThisOffer()
    {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Вы действительно хотите удалить предложение?', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }

      if (!confirmation) {
        return
      }

      try {
        await this.deleteOffer(this.offer.id)
        this.$message.success('Предложение успешно удалено')
        await this.$router.push({name: 'OffersList'})
      } catch (e) {
        this.$message.error('Не получилось удалить предложение.')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.wish-info-label
  margin-bottom: 10px
  font-weight: bold

.wish-info-wrapper
  display: flex

  > *
    margin-right: 50px

.panel--mb
  margin-bottom: 25px

.comparing-table__label
  margin-bottom: 4px
  font-weight: 600

</style>
