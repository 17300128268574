import problemTicketList from '@/pages/help-center/tickets-problem/ListPage'
import viewProblemTicket from '@/pages/help-center/tickets-problem/ViewPage'
import ticketList from '@/pages/help-center/tickets/ListPage'
import viewTicketPage from '@/pages/help-center/tickets/ViewPage'

export default [
  {
    path: '/help-center/tickets/list',
    name: 'TicketList',
    component: ticketList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/tickets-problem/list',
    name: 'problemTicketList',
    component: problemTicketList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/tickets/:id',
    name: 'ViewTicketPage',
    component: viewTicketPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/tickets-problem/:id',
    name: 'ViewProblemTicket',
    component: viewProblemTicket,
    meta: {
      requiresAuth: true,
    },
  },
]
