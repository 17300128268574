<template>
  <div>
    <PageHeader link-name="Назад" link-path="ComplaintsList" page-name="Модерирование Жалобы">
      <template>
        <div class="title-bar__button-wrapper">
          <router-link class="button-wrapper-link" :to="{name: 'ComplaintsList'}">
            <el-button type="gray">
              <i class="fas fa-list" /> Список
            </el-button>
          </router-link>

          <router-link
            class="button-wrapper-link"
            :to="{name: 'LogsList', params: {id: complaint && complaint.id, subject: 'complaint'}}"
          >
            <el-button type="info">
              <i class="fas fa-info-circle" /> Действия над Жалобой
            </el-button>
          </router-link>
        </div>
      </template>
    </PageHeader>

    <MainPanel v-loading="loading">
      <table v-if="complaint" class="key-values-table">
        <tr>
          <td>Причина</td>
          <td>{{ complaint.reason }}</td>
        </tr>

        <tr>
          <td>Тип</td>
          <td>
            {{ complaint.type === 'wish' ? 'Желание' : '' }}
            {{ complaint.type === 'offer' ? 'Предложение' : '' }}
          </td>
        </tr>

        <tr>
          <td>Создана</td>
          <td>{{ complaint.created }}</td>
        </tr>

        <tr v-if="complaint.created_at">
          <td>Дата создания</td>
          <td>{{ complaint.created_at }}</td>
        </tr>

        <tr>
          <td>Статус</td>
          <td>
            <el-tag
              class="type-tag"
              size="small"
              :type="getStatusTagType(complaint.status)"
            >
              {{ complaint.status && complaint.status.replace('_', ' ') }}
            </el-tag>
          </td>
        </tr>

        <tr>
          <td>Пользователь</td>
          <td>
            <router-link class="name-wrapper" :to="{name: 'ViewUser', params: {id: complaint.user.id}}">
              <img
                v-if="complaint.user.avatar"
                alt="Avatar"
                class="avatar"
                :class="{'avatar--business': complaint.user.is_business}"
                :src="complaint.user.avatar"
              >
              {{ complaint.user.username }}
            </router-link>
          </td>
        </tr>

        <tr>
          <td>Подтверждение</td>
          <td>
            <el-button-group>
              <el-button
                :disabled="!complaint.actions.discard"
                size="small"
                :type="complaint.state === 'DISCARDED' ? 'danger' : ''"
                @click="$refs.checkReasonModal.open()"
              >
                Нет
              </el-button>
              <el-button
                :disabled="!complaint.actions.approve"
                size="small"
                :type="complaint.state === 'CHECKED' ? 'success' : ''"
                @click="onApproveComplaint"
              >
                Да
              </el-button>
            </el-button-group>
          </td>
        </tr>
      </table>
    </MainPanel>

    <Panel title="Чаты между пользователями">
      <table>
        <tr v-for="(chat, index) in chats" :key="index">
          <td>
            <router-link :to="{name: 'ViewComplaintChat', params: {complaintId: complaint.id, chatId: chat.id}}">
              {{ chat.title }}
            </router-link>
          </td>
        </tr>
      </table>
    </Panel>

    <CheckReasonModal ref="checkReasonModal" @check="onDeclineComplaint" />
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions, mapGetters} from 'vuex'
import CheckReasonModal from '@/components/complaints/CheckReasonModal'
import Panel from '@/components/common/Panel'
import PageHeader from '@/components/common/PageHeader';


export default {
  metaInfo: {
    title: 'Модерирование Жалобы',
  },
  components: {Panel, CheckReasonModal, MainPanel, PageHeader},
  data() {
    return {
      loading: false,
      complaint: null,
      chats: [],
    }
  },
  computed: {
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
    complaintId() {
      return this.$route.params.id
    },
  },
  async created() {
   await this.getComplaint()
    this.chats = await this.getChatsBetweenUsersInComplaint(this.complaintId)
  },
  methods: {
    ...mapActions('complaints', [
      'fetchComplaint',
      'approveComplaint',
      'declineComplaint',
      'getChatsBetweenUsersInComplaint',
      'getDialogsInChatsBetweenTwoUsers',
    ]),
    async onApproveComplaint() {
      await this.approveComplaint(this.complaintId)
      await this.getComplaint()
    },
    async onDeclineComplaint(form) {
      await this.declineComplaint({
        id: this.complaintId,
        form,
      })
      await this.getComplaint()
    },
    async getComplaint() {
      this.complaint = await this.fetchComplaint(this.complaintId)
    },
  },
}
</script>

