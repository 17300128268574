<template>
  <el-cascader
    :disabled="disabled ? true : false"
    :options="getCategoriesForSelector"
    :placeholder="placeholder ? placeholder : 'Select'"
    :props="{ checkStrictly: true }"
    style="display: block"
    :value="value"
    @change="onChange"
  />
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'CategorySelector',
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('wishes/categories', [
      'getCategoriesForSelector',
    ]),
  },
  async created() {
    await this.fetchCategories()
  },
  methods: {
    ...mapActions('wishes/categories', ['fetchCategories']),
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>

</style>
