<template>
  <MainPanel v-loading="loading" class="panel">
    <div class="panel-header">
      <div class="checkboxes-wrapper">
        <div class="is-done-checkbox-wrapper">
          <el-checkbox
            v-model="model.is_complete"
            class="is-done-checkbox"
            :label="model.code"
            @change="onToggleComplete"
          />
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="label">
          UA
        </div>
        <el-input
          v-model="model.data.ua"
          rows="7"
          type="textarea"
          @input="isFieldsChanged = true"
        />
        <div class="field-counter">
          {{ model.data.ua && model.data.ua.length || 0 }}
        </div>
      </div>
    </div>

    <div v-if="isFieldsChanged" class="text-right">
      <el-button type="success" @click="onSave">
        Сохранить
      </el-button>
    </div>
  </MainPanel>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'

export default {
  name: 'Translation',
  components: {MainPanel},
  props: {
    model: {
      type: Object,
      require: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      isFieldsChanged: false,
    }
  },
  methods: {
    ...mapActions('translations', [
      'toggleTranslationComplete',
      'saveTranslation',
    ]),
    async onToggleComplete() {
      await this.toggleTranslationComplete({
        category_id: this.$route.params.id,
        translation_id: this.model.id,
        is_complete: this.model.is_complete,
      })
    },
    async onSave() {
      this.loading = true
      await this.saveTranslation({
        category_id: this.$route.params.id,
        translation_id: this.model.id,
        data: this.model.data,
      })
      this.loading = false
      this.isFieldsChanged = false
    },
  },
}
</script>

<style lang="sass" scoped>

.columns
  display: flex
  margin-right: -10px
  margin-left: -10px

.column
  width: 50%
  padding-right: 10px
  padding-left: 10px

.label
  font-weight: bold
  font-size: 12px
  color: #000
  margin-bottom: 0
  padding-left: 0
  display: block
  text-align: left

.translation-title
  color: #888888
  font-size: 13px
  font-weight: bold
  margin-bottom: 15px

.panel
  margin-bottom: 15px

.panel-header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px

.checkboxes-wrapper
  display: flex
  align-items: center
  justify-content: space-between

.field-counter
  text-align: right
  font-size: 11px
  color: #999999
  position: relative
  top: -17px
  right: 7px

.is-done-checkbox-wrapper
  margin-right: 30px

::v-deep .el-checkbox
  margin-bottom: 0
  position: relative
  top: 1px

::v-deep .el-checkbox__inner
  width: 24px
  height: 24px
  border-width: 2px

  &:after
    height: 13px
    width: 5px
    left: 7px
    top: 1px
    border-width: 2px


</style>
