import api from '@/utils/api'

export default {

  actions: {
    async fetchInternalWish(_, id) {
      const res = await api.http.get(`/admin/wishes/internal/${id}`)
      return res?.data.data
    },

    async fetchInternalWishes(_, {user_id, state}) {
      const usp = new URLSearchParams({
        ...(user_id && {user_id}),
        ...(state && { state }),
      });
      const url = usp.toString();
      const res = await api.http.get(`/admin/wishes/internal?${url}`)
      return res?.data.data
    },
  },


  namespaced: true,
}
