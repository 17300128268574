<template>
  <div v-if="user">
    <PageHeader link-name="Назад" link-path="UsersList" page-name="Пользователь">
      <template>
        <div v-if="user" class="title-bar__button-wrapper">
          <router-link class="button-wrapper-link" :to="{name: 'UsersList'}">
            <el-button type="gray">
              <i class="fas fa-list" /> Список
            </el-button>
          </router-link>
          <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: user && user.id, subject: 'user'}}">
            <el-button type="info">
              <i class="fas fa-info-circle" /> Действия Пользователя
            </el-button>
          </router-link>
          <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: user && user.id, subject: 'overUser'}}">
            <el-button type="info">
              <i class="fas fa-info-circle" /> Действия над Пользователем
            </el-button>
          </router-link>
        </div>
        <el-avatar
          v-if="user.avatar"
          shape="square"
          :size="50"
          :src="user.avatar"
        />
      </template>
    </PageHeader>


    <MainPanel v-loading="loading">
      <table v-if="user" class="user-data">
        <tr>
          <td><strong>ID</strong></td>
          <td>{{ user && user.id }}</td>
        </tr>
        <tr>
          <td><strong>Email</strong></td>
          <td>{{ user && user.email }}</td>
        </tr>
        <tr>
          <td><strong>Телефон</strong></td>
          <td>{{ user && user.phone }}</td>
        </tr>
        <tr>
          <td><strong>Дней после регистрации</strong></td>
          <td>{{ user && user.count_days_after_register }}</td>
        </tr>
        <tr v-if="user && user.documents">
          <td><strong>Документы</strong></td>
          <td>
            <div class="documents-wrapper">
              <a
                v-for="document in user.documents"
                :key="document.id"
                class="document"
                :download="document.name"
                :href="document.path"
                target="_blank"
              >
                <i class="fas fa-file-alt" />
                <div>
                  {{ document.name }}
                </div>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Дата последнего визита</strong></td>
          <td>{{ user && user.last_visited }}</td>
        </tr>
        <tr>
          <td><strong>Количество дней после регистрации</strong></td>
          <td>{{ user && user.statistic.count_days_after_register }}</td>
        </tr>
        <tr>
          <td><strong>Подтвержден</strong></td>
          <td>
            <el-button-group v-if="user">
              <el-button
                size="small"
                :type="!user.is_approved ? 'danger' : ''"
                @click="onDiscardUser(user.id)"
              >
                Нет
              </el-button>
              <el-button
                size="small"
                :type="user.is_approved ? 'success' : ''"
                @click="onApproveUser(user.id)"
              >
                Да
              </el-button>
            </el-button-group>
          </td>
        </tr>
        <tr>
          <td><strong>Блокировка</strong></td>
          <td>
            <div v-if="timesOfBan.length">
              <el-dropdown v-if="user.actions.soft_ban" @command="(time) => handleBanUser('soft', time)">
                <el-button size="small" type="success">Легкая<i class="el-icon-arrow-down el-icon--right" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="time of timesOfBan" :key="time[0]" :command="time[0]">
                    {{ time[1] }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-dropdown v-if="user.actions.hard_ban" @command="(time) => handleBanUser('hard', time)">
                <el-button size="small" type="danger">Жесткая<i class="el-icon-arrow-down el-icon--right" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="time of timesOfBan" :key="time[0]" :command="time[0]">
                    {{ time[1] }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-button v-if="user.actions.unban" size="small" @click="unban">Снять</el-button>
            </div>
          </td>
        </tr>
      </table>
    </MainPanel>
    <LimitManagement />
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'
import LimitManagement from '@/components/users/LimitManagement';
import PageHeader from '@/components/common/PageHeader';



export default {
  metaInfo: {
    title: 'Пользователь',
  },
  components: {MainPanel,LimitManagement, PageHeader},
  data() {
    return {
      user: null,
      loading: false,
      timesOfBan: [],
    }
  },
  async created() {
    this.getUser()
    const times = await this.fetchTimesOfBan()
    this.timesOfBan = Object.entries(times)
  },
  methods: {
    ...mapActions('users', [
      'fetchUser',
      'approveUser',
      'discardUser',
      'hardBanUser',
      'softBanUser',
      'fetchTimesOfBan',
      'unbanUser',
    ]),

    async getUser() {
      this.user = await this.fetchUser(this.$route.params.id)
      console.log(this.user)
    },
    async onDiscardUser(id) {
      await this.discardUser(id)
      await this.getUser()
    },
    async onApproveUser(id) {
      await this.approveUser(id)
      await this.getUser()
    },
    async handleBanUser(type, ban_time) {
      let confirmation = false

      try {
        confirmation = await this.$confirm('Вы действительно хотите заблокировать пользователя?', 'Блокировка', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {

      }

      if (!confirmation) return

      try {
        switch (type) {
          case 'hard':
            this.user = await this.hardBanUser({userId: this.user.id, ban_time})

            this.$message.success('Пользователь успешно заблокирован.')
            break

          case 'soft':
            this.user = await this.softBanUser({userId: this.user.id, ban_time})

            this.$message.success('Пользователь успешно заблокирован.')
            break

          default:
            break
        }
      } catch (e) {
        this.$message.error('Не удалось назначить бан пользователю.')
      }
    },
    async unban() {
      try {
        this.user = await this.unbanUser(this.user.id)
        this.$message.success('Пользователь успешно разблокирован.')
      } catch (e) {
        this.$message.error('Не удалось снять бан с пользователя.')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.big-avatar-wrapper
  display: flex
  align-items: center


.big-avatar
  height: 90px
  width: 90px
  margin: 6px 20px 6px 6px
  border: 6px solid $color-bg
  box-shadow: 0 0 0 6px $color-text-accent-customer
  border-radius: 50%

  &.big-avatar--business
    border: 6px solid #ffffff
    box-shadow: 0 0 0 6px $color-text-accent-business

.user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none

.document
  display: flex
  align-items: center
  font-size: 12px
  padding-bottom: 5px
  padding-top: 5px

  i
    font-size: 20px
    margin-right: 7px
</style>
