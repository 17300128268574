<template>
  <el-card style="margin-bottom: 10px">
    <div class="page-header">
      <div class="page-header__main-content">
        <a
          v-if="linkName"
          class="el-icon-arrow-left page-header__back-link"
          @click="linkPassed"
        >
          {{ linkName }}
        </a>
        <el-divider
          v-if="linkName"
          class="page-header__divider"
          direction="vertical"
        />
        <span class="page-header__title">{{ pageName }}</span>
      </div>
      <div class="page-header__additional-content">
        <slot />
      </div>
    </div>
  </el-card>
</template>

<script>

export default {
  name: 'PageHeader',

  props: {
    pageName: {
      type: String,
    },
    linkName: {
      type: String,
      required: false,
    },
    linkPath: {
      type: String,
      required: false,
    },
  },

  methods: {
    linkPassed() {
      return this.linkPath ? this.$router.push({name: this.linkPath}) : this.$router.back()
    },
  },
}
</script>

<style scoped>

</style>
