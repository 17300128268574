<template>
  <div>
    <div class="title-bar">
      <h1>Управление файлами переводов</h1>

      <div class="title-bar__button-wrapper">
        <el-button icon="el-icon-delete" type="danger" @click="onClean">
          Удалить переводы
        </el-button>

        <a :href="`${baseUrl}/admin/translations/export?target=BACKEND`">
          <el-button type="success">
            <i class="fas fa-cloud-download-alt" />
            Экспорт файлов Back End
          </el-button>
        </a>

        <a :href="`${baseUrl}/admin/translations/export?target=FRONTEND`">
          <el-button type="success">
            <i class="fas fa-cloud-download-alt" />
            Экспорт файлов Front End
          </el-button>
        </a>

        <a :href="`${baseUrl}/admin/translations/export?target=HELP`">
          <el-button type="success">
            <i class="fas fa-cloud-download-alt" />
            Экспорт файлов Help Center
          </el-button>
        </a>
      </div>
    </div>

    <MainPanel>
      <h2 class="h2-mb">
        Импорт файлов
      </h2>
      <el-form
        ref="form"
        label-position="top"
        :model="form"
      >
        <div class="row">
          <div class="col-md-4">
            <el-form-item label="Выберите язык" prop="lang">
              <el-select
                v-model="form.lang"
                placeholder="Выберите из списка"
              >
                <el-option
                  v-for="lang in ['ua']"
                  :key="lang"
                  :label="lang"
                  :value="lang"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="Frontend/Backend" prop="target">
              <el-select
                v-model="form.target"
                placeholder="Выберите из списка"
              >
                <el-option
                  v-for="target in ['FRONTEND', 'BACKEND', 'HELP']"
                  :key="target"
                  :label="target"
                  :value="target"
                />
              </el-select>
            </el-form-item>
          </div>

          <div class="col-md-8">
            <el-form-item
              class="el-form-item--mb-0"
              :class="{'dropzone-disabled': !form.target || !form.lang}"
              label="Загрузите файлы переводов с расширением .php или .js"
              prop="files"
            >
              <el-upload
                accept=".js, .php"
                :action="`${baseURL}/admin/translations/import?target=${form.target}&lang=${form.lang}`"
                :disabled="!form.target || !form.lang"
                drag
                :file-list="fileList"
                :headers="{'Authorization': `Bearer ${token}`}"
                multiple
                name="files[]"
                :on-error="onError"
                :on-success="onSuccess"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  Перетяните файлы сюда или <em>кликните для загрузки</em>
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {dropzoneErrorHandler} from '@/utils/error'
import {mapActions} from 'vuex'

export default {
  metaInfo: {
    title: 'Управление файлами переводов',
  },
  components: {MainPanel},
  data() {
    return {
      form: {
        files: [],
        lang: null,
        target: null,
      },
      baseUrl: process.env.VUE_APP_BASE_API_URI,
      fileList: [],
      baseURL: process.env.VUE_APP_BASE_API_URI,
      token: localStorage.getItem('token'),
    }
  },
  methods: {
    ...mapActions('translations', [
      'cleanTranslations',
      'fetchTranslationsCategories',
    ]),
    async onSuccess() {
      this.$message({
        message: 'Файл успешно загружен',
        type: 'success',
      })
      await this.fetchTranslationsCategories()
    },
    async onClean() {
      try {
        const res = await this.$confirm('Вы действительно хотите удалить переводы?')
        if (res) {
          await this.cleanTranslations()
          this.$message({
            message: 'Переводы удалены',
            type: 'success',
          })
          await this.fetchTranslationsCategories()
        }
      } catch {
      }
    },
    onError(err) {
      dropzoneErrorHandler(err)
    },
  },
}
</script>

<style lang="sass" scoped>

.dropzone-disabled
  opacity: 0.5

  ::v-deep .el-upload-dragger
    background: #e0e0e0
    cursor: not-allowed

.el-form-item--mb-0
  margin-bottom: -15px

.h2-mb
  margin-bottom: 30px

::v-deep .el-upload-list__item:last-of-type
  margin-bottom: 20px

::v-deep .el-upload__input
  display: none

::v-deep .el-upload
  width: 100%

::v-deep .el-upload-dragger
  width: 100%
  height: 170px

::v-deep .el-icon-upload
  font-size: 100px
  margin-top: 60px
  color: #dddddd

</style>
