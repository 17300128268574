<template>
  <div>
    <el-drawer
      direction="ltr"
      size="33%"
      :title="formTitle"
      :visible.sync="isDrawerOpen"
    >
      <div class="internal-form">
        <el-form
          ref="form"
          label-position="top"
          label-width="220px"
          :model="form"
        >
          <el-form-item label="Имя пользователя EgoList">
            <el-input v-model="form.username" />
            {{ $_ErrorMixin_getError('username') }}
          </el-form-item>

          <el-form-item label="Имя">
            <el-input v-model="form.first_name" />
            {{ $_ErrorMixin_getError('first_name') }}
          </el-form-item>

          <el-form-item label="Фамилия">
            <el-input v-model="form.last_name" />
            {{ $_ErrorMixin_getError('last_name') }}
          </el-form-item>

          <el-form-item label="Тип аккаунта">
            <el-select v-model="form.account_type" placeholder="Тип аккаунта" @change="getPackages()">
              <el-option label="PERSONAL" value="PERSONAL" />
              <el-option label="BUSINESS" value="BUSINESS" />
            </el-select>
            {{ $_ErrorMixin_getError('account_type') }}
          </el-form-item>

          <el-form-item label="Тип пакета">
            <el-select v-model="form.package_type" :disabled="!form.account_type" placeholder="Тип пакета">
              <el-option
                v-for="(item) in account_packages"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              />
            </el-select>
            {{ $_ErrorMixin_getError('package_type') }}
          </el-form-item>

          <el-form-item label="Дата создания пользывателя">
            <el-col :span="11">
              <el-date-picker
                v-model="form.registration_date"
                placeholder="Дата"
                type="date"
              />
            </el-col>
            {{ $_ErrorMixin_getError('registration_date') }}
          </el-form-item>

          <el-form-item label="Рейтинг">
            <el-input-number v-model="form.rating" :max="10" :min="1" />
          </el-form-item>

          <el-form-item label="Достижение">
            <el-checkbox v-for="achievement in achievementsOptions" :key="achievement.title" v-model="achievement.checked">{{ achievement.title }}</el-checkbox>
          </el-form-item>

          <div class="internal-form__upload-image">
            <el-upload
              ref="upload"
              action=""
              class="upload-demo"
              drag
              :http-request="onFileSelect"
              :limit="1"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </div>
          {{ $_ErrorMixin_getError('file') }}
        </el-form>
        <el-button type="success" @click="save">
          Сохранить
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import ErrorMixin from '@/utils/ErrorMixin';

const defaultValues = {
  username: '',
  first_name: '',
  last_name: '',
  account_type: '',
  avatar: '',
  rating:'',
  registration_date: new Date().toISOString().slice(0, 10),
};

export default {
  name: 'InternalUserForm',
  mixins: [ErrorMixin],
  data() {
    return {
      formTitle: null,
      isDrawerOpen: false,
      account_packages: null,
      selectedFile: null,
      formData : null,
      editUser: null,
      oldUser: false,
      achievementsOptions: [
        {type: 'COMPLAINTS_FROM', title: 'Шельма', checked: false},
        {type: 'COMPLAINTS_TO', title: 'Шериф',checked: false},
        {type: 'UNIQUENESS_CHECKS', title: 'Унікальний',checked: false},
        {type: 'COMPLETED_PROFILE', title: 'Відкрита книга',checked: false}],
      form: {...defaultValues, package_type: this.account_packages, achievements: []},
    };
  },

  methods:{
    ...mapActions('internalUsers', [
      'fetchPackagesType', 'createUser', 'uploadImage', 'changeUser', 'fetchInternalUsers',
    ]),

  async onSubmit() {
      try {
         await this.createUser(this.form)
          this.$message({
          message: 'Новый пользователь был создан',
          type: 'success',
        });
        this.$_ErrorMixin_clearErrors()
        this.form = {...defaultValues, package_type: null}
        this.$refs.upload.clearFiles();
        this.form.acchievements = []
        this.$emit('update')
        this.isDrawerOpen = false
      } catch (e){
        this.$_ErrorMixin_handleError(e)
      }
    },

   async getPackages(type) {
      if(this.form.account_type !== type){
        this.form.package_type = ''
      }
     this.account_packages = await this.fetchPackagesType(this.form.account_type)
   },

   async upLoad() {
       this.formData = await new FormData()
       await  this.formData.append('file', this.selectedFile, this.selectedFile.name)
      try {
        this.form.avatar =  await this.uploadImage(this.formData)
      } catch (e){
        this.$_ErrorMixin_handleError(e)
      }
   },

   async onFileSelect(res) {
      this.selectedFile = await res.file
      await this.upLoad()
    },

    async openDrawer(userToEdit = null) {
      this.form = {...defaultValues, package_type: null, achievements: []}
      this.achievementsOptions.forEach((el) => el.checked = false)
      this.isDrawerOpen = true
      this.formTitle = userToEdit ? 'Редактирование Пользователя'  : 'Создание Пользователя'
      this.oldUser = !!userToEdit
      if (!userToEdit) return
      this.editUser = userToEdit
      this.form = this.editUser
      await this.getPackages(this.form.account_type)
      this.editUser.achievements.filter((achievement) => {
        if (achievement.title) {
          this.achievementsOptions.find((el) => {
            if (el.title === achievement.title) {
              el.checked = true
            }
          })
        }
      })
    },

    achievementsToSend() {
      this.achievementsOptions.filter((selected) => {
        if(selected.checked){
          this.form.achievements.push(selected.type)
        }
      })
    },

   async save(){
     this.achievementsToSend()
     if(!this.oldUser) {
       await this.onSubmit()
       return
      }
      try {
       if ( typeof this.form.avatar === 'object') {
         this.form.avatar = ''
       }
        await this.changeUser(this.form)
        this.form.achievements = []
        this.$message({
          message: 'Новый пользователь был изменен',
          type: 'info',
        })
        this.isDrawerOpen = false
        this.$router.go(0)
      } catch (e){
        this.$_ErrorMixin_handleError(e)
        await this.$message.error('Ошибка!');
      }
    },
  },
}
</script>

