<template>
  <div>
    <div class="title-bar">
      <h1>Переводы категории {{ category.target }}/{{ category.name }}</h1>
    </div>
    <div class="symbol-counter">
      Всего символов: {{ category.chars_count }}
    </div>

    <Translation
      v-for="item in translations"
      :key="item.key"
      v-loading="loading"
      :model="item"
      @refetch-data="getTranslations"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import Translation from '@/components/translations/Translation'

export default {
  name: 'TranslationsPage',
  metaInfo: {
    title: 'Переводы',
  },
  components: {Translation},
  data() {
    return {
      deployLoading: false,
      loading: false,
    }
  },
  computed: {
    ...mapState('translations', [
      'translations',
    ]),
    ...mapGetters('translations', [
      'getTranslationCategory',
    ]),
    category() {
      return this.getTranslationCategory(this.$route.params.id)
    },
  },
  watch: {
    $route() {
      this.getTranslations()
    },
  },
  created() {
    this.getTranslations()
  },
  destroyed() {
    this.setTranslations = []
  },
  methods: {
    ...mapMutations('translations', [
      'setTranslations',
    ]),
    ...mapActions('translations', [
      'fetchTranslations',
    ]),
    async getTranslations() {
      this.loading = true
      await this.fetchTranslations(this.$route.params.id)
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>

.symbol-counter
  position: relative
  top: -15px

</style>
